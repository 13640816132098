
import Navbar from "@/components/Navbar/NavbarPure.vue";
import ethService from "@/services/eth";
import { NoticeBar } from "vant";
import Web3 from "web3";
const BN = Web3.utils.BN;

export default {
  components: {
    Navbar,
    NoticeBar,
  },
  data() {
    return {
      dataLoading: false,
      checkHaveMap: "",
      wallet: {
        account: "",
        balance: "",
      },
      pathData: {
        amount: "",
        path: [],
        address: "",
      },
      claim: {
        total: "",
        released: "",
        unlockedAmount: "",
      },
      distributeDPRLoading: false,
      claimLoading: false,
      disablePage: true,
    };
  },
  async mounted() {
    await ethService
      .init({
        onAccountsChanged: (accounts) => {
          this.disablePage = false;
          this.wallet.account = accounts[0];
          this.getData();
        },
      })
      .catch((e) => {
        console.log(e);
      });
    this.getData();
  },
  methods: {
    async getData() {
      this.dataLoading = true;
      let accounts = await ethService.getAccount().catch((e) => {
        console.log(e);
        this.$Notify({
          type: "danger",
          message: e.message,
        });
      });
      this.wallet.account = accounts[0];
      let balance = await ethService.getBalance(this.wallet.account);
      this.wallet.balance = (balance / 1e18).toFixed(8) / 1;
      let total = await ethService.claimH.getTotalLockAmount(
        this.wallet.account
      );
      this.claim.total = total / 1e18;
      let released = await ethService.claimH.getReleasedAmount(
        this.wallet.account
      );
      this.claim.released = (released / 1e18).toFixed(8) / 1;

      let unlockedAmount = await ethService.claimH.getUnlockedAmount(
        this.wallet.account
      );
      this.claim.unlockedAmount = (unlockedAmount / 1e18).toFixed(8) / 1;
      this.dataLoading = false;
    },
    async claimDPR() {
      this.claimLoading = true;
      let result = await ethService.claimH
        .claim(this.wallet.account)
        .catch((e) => {
          this.$Notify({
            type: "danger",
            message: e.message,
          });
        });
      this.claimLoading = false;
      if (result.blockHash) {
        this.$toast.success("success");
        this.getData();
      }
      console.log(result);
    },
  },
};
